import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = ({ data }) => (
  <Layout>
    <div className="container">
      <h1>{data.notfound.title}</h1>
      <p>{data.notfound.body}</p>
    </div>
  </Layout>
);

NotFoundPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery {
    notfound: datoCmsNotFound {
      title,
      body
    }
  }
`;
